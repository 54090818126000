import { Language } from 'interfaces/Language'
import {
    adsSurveyRedirectRoute,
    appChatRoute,
    appCreateKnowsRoute,
    appEditProfileRoute,
    appFeedsRoute,
    appFlashcardsRoute,
    appLibraryRoute,
    appMyKnowsRoute,
    appPayoutsHistoryRoute,
    appPayoutsRequestRoute,
    appPayoutsRoute,
    appSearchRoute,
    appSubjectsKnowsRoute,
    appUnavailableFeatureRoute,
    chatsRedirectRoute,
    learningBotRedirectRoute,
    pollsRedirectRoute,
    proPlanActivateRoute,
    userTopicQuizRedirectRoute,
    usersRedirectRoute,
} from './routes'
import { getCookieConsent } from 'lib/tracking/storage'

export function getLandingPageSlugs(locale: Language) {
    switch (locale) {
        case Language.German:
            return [
                'advertise',
                'for-parents',
                'download',
                'pro-plan',
                'checkout',
                'success',
                'press',
                'activate',
                'download',
                'download-know',
                'profile',
                'company',
            ]
        case Language.French:
            return ['explanation', 'download', 'press', 'pro-plan', 'success', 'activate', 'download-know', 'profile']
        case Language.Polish:
            return [
                'expansion',
                'explanation',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'profile',
            ]
        case Language.BritishEnglish:
            return [
                'profile',
                'expansion',
                'explanation',
                'expansion-scotland',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
            ]
        case Language.Italian:
            return [
                'expansion',
                'explanation',
                'download',
                'press',
                'pro-plan',
                'success',
                'activate',
                'download-know',
                'profile',
            ]
        case Language.AmericanEnglish:
            return [
                'explanation',
                'press',
                'expansion',
                'college-scholarship',
                'download',
                'activate',
                'download-know',
                'profile',
            ]
        case Language.Spanish:
        case Language.ColombiaSpanish:
        case Language.Turkish:
            return ['press', 'download', 'activate', 'download-know', 'profile']
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export function getPageWithoutMessengerWidget(locale: Language) {
    switch (locale) {
        case Language.German:
        case Language.French:
        case Language.Polish:
        case Language.BritishEnglish:
        case Language.Italian:
            return []
        case Language.Spanish:
        case Language.AmericanEnglish:
        case Language.ColombiaSpanish:
        case Language.Turkish:
            return ['/knows/[slug]', '/knows/u/[slug]', '/']
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export const isSeoKnowPage = (pathname: string) => {
    const knowPagesPathnames = ['/knows/[slug]', '/knows/u/[slug]']
    return !!knowPagesPathnames.includes(pathname)
}

export function getPagesWithoutNavbarAndFooter(locale: Language) {
    switch (locale) {
        case Language.German:
        case Language.French:
        case Language.Polish:
        case Language.BritishEnglish:
        case Language.AmericanEnglish:
        case Language.Italian:
        case Language.Spanish:
        case Language.ColombiaSpanish:
        case Language.Turkish:
            return [
                appFeedsRoute,
                appSearchRoute,
                '/app/knows/[slug]',
                appUnavailableFeatureRoute,
                appMyKnowsRoute,
                appChatRoute,
                appLibraryRoute,
                appFlashcardsRoute,
                appEditProfileRoute,
                appSubjectsKnowsRoute,
                appCreateKnowsRoute,
                appPayoutsRoute,
                appPayoutsHistoryRoute,
                appPayoutsRequestRoute,
                `/app/library/[slug]`,
                proPlanActivateRoute,
                // redirects
                learningBotRedirectRoute,
                adsSurveyRedirectRoute,
                pollsRedirectRoute,
                chatsRedirectRoute,
                userTopicQuizRedirectRoute,
                usersRedirectRoute,
            ]
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export function getPagesWithoutFooter(locale: Language) {
    switch (locale) {
        case Language.AmericanEnglish:
            return ['/subjects/study-guide/[slug]']
        case Language.German:
        case Language.French:
        case Language.Polish:
        case Language.BritishEnglish:
        case Language.Italian:
        case Language.Spanish:
        case Language.ColombiaSpanish:
        case Language.Turkish:
            return []
        default:
            throw new Error(`Unknown language: ${locale}`)
    }
}

export const shouldEnableClarityForMinorityOfUsers = () => {
    if (typeof window === 'undefined') return false

    const cookieConsent = getCookieConsent()
    if (!cookieConsent?.statistics) {
        return false
    }
    return Math.random() < 0.1
}
