import NewButton, { NewButtonLink } from 'components/elements/NewButton'
import { ExperimentVariant } from 'lib/features/activeExperiments'
import { appDownloadUrl } from 'lib/features/app-links'
import _useTranslation from 'lib/hooks/_useTranslation'
import useExperiments from 'lib/hooks/useExperiments'
import { useToggleState } from 'lib/hooks/useToggleState'
import { Body2, ButtonText } from 'stories/elements/Typography/Text'
import theme from 'stories/utils/theme'
import styled from 'styled-components'
import DownloadQRCode from 'components/feature/App/widgets/QRCode'
import Stack from 'components/elements/Stack'
import { useCallback, useEffect, useRef } from 'react'
import { trackEvent } from 'lib/tracking/analytics-service'
import {
    AnalyticsEventDownloadDropdownImpression,
    AnalyticsEventDownloadDropdownStoreClick,
} from 'lib/constants/AnalyticsEventType'
import QRCodeFrame from 'assets/header/download-dropdown-qr-frame.webp'
import NextImage from 'components/elements/NextImage'
import { useRouter } from 'next/router'
import { parseLocale } from 'lib/constants/localization'
import { returnDownloadButton } from 'lib/features/downloadNativeButtons'

const Container = styled.div<{ isLandingPage?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: ${theme.zIndex.cookieBanner};
    .dropdown {
        position: absolute;
        top: 55px;
        right: 0;
        min-width: 475px;
        height: fit-content;
        padding: 25px 25px 35px 25px;
        gap: 15px;
        display: flex;
        border: 1px solid red;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${theme.colors.lightAccentBorder};
        border: 1px solid ${theme.colors.lightAccentBorder};
        border-radius: ${theme.borderRadius.normal};
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 8px;
        z-index: ${theme.zIndex.cookieBanner};
        ::after {
            content: '';
            position: absolute;
            top: -15px;
            right: ${({ isLandingPage }) => (isLandingPage ? 95 : 75)}px;
            width: 0px;
            height: 0px;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-bottom: 15px solid ${theme.colors.lightAccentBorder};
        }
    }
    .background-shadow {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        z-index: 69;
        display: table;
        transition: all 0.3s ease;
        animation: showModalShadow 0.35s 1 ease-in-out forwards;
        @keyframes showModalShadow {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: space-around;
        width: 100%;
    }
    .qr {
        position: relative;
        .frame {
            position: absolute;
            left: -25px;
            top: -12px;
            z-index: 10;
        }
    }
    .separator {
        width: 100%;
        display: flex;
        top: 55px;
        height: 25px;
        justify-content: center;
        align-items: center;
        gap: 30px;
        div {
            width: 100px;
            height: 1px;
            border-radius: 10px;
            background-color: ${theme.colors.white};
        }
    }
`

interface Props {
    isLaunched: boolean
    isLandingPage: boolean
    referrerScreen?: string
}

const HeaderDownloadDesktopButton = ({ isLaunched, isLandingPage, referrerScreen }: Props) => {
    const [openDownloadAppDropdown, toggleDownloadAppDropdown] = useToggleState(false)
    const experiments = useExperiments()
    const menuRef = useRef<HTMLDivElement>(null)
    const { locale } = useRouter()
    const parsedLocale = parseLocale(locale)
    const { t: tCommon } = _useTranslation('common')

    const showDownloadAppDropdown =
        experiments?.SHOW_DOWNLOAD_APP_DROPDOWN === ExperimentVariant.showDownloadAppDropdownVariant1

    const handleMouseDown = useCallback(
        (event: MouseEvent) => {
            const isClickOutside = !menuRef.current?.contains(event.target as Node)
            if (isClickOutside && openDownloadAppDropdown) {
                toggleDownloadAppDropdown()
            }
        },
        [openDownloadAppDropdown, toggleDownloadAppDropdown]
    )

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown)
        return () => {
            document.removeEventListener('mousedown', handleMouseDown)
        }
    }, [handleMouseDown])

    const onOpenDownloadAppDropdown = () => {
        trackEvent(AnalyticsEventDownloadDropdownImpression, { referrerScreen })
        toggleDownloadAppDropdown()
    }

    const onStoreButtonClick = (type: 'android' | 'ios') => {
        trackEvent(AnalyticsEventDownloadDropdownStoreClick, { value: type, referrerScreen })
        setTimeout(() => {
            const url = appDownloadUrl(type, 'download_dropdown')
            window.open(url, '_newtab')
        }, 200)
    }

    if (!isLaunched) return null

    return (
        <Container isLandingPage={isLandingPage}>
            {showDownloadAppDropdown ? (
                <NewButton
                    height={40}
                    width={isLandingPage ? 220 : 180}
                    borderRadius={theme.borderRadius.normal}
                    onClick={onOpenDownloadAppDropdown}
                    color={theme.colors.deepSeaBlue}
                >
                    <ButtonText color={theme.colors.white}>{tCommon('common/conversionCTAButton')}</ButtonText>
                </NewButton>
            ) : (
                <NewButtonLink
                    height={40}
                    width={isLandingPage ? 220 : 180}
                    borderRadius={theme.borderRadius.normal}
                    type="white"
                    href={appDownloadUrl('dynamic', 'header')}
                >
                    <ButtonText color={theme.colors.deepSeaBlue}>{tCommon('common/conversionCTAButton')}</ButtonText>
                </NewButtonLink>
            )}
            {openDownloadAppDropdown ? (
                <>
                    <div className="dropdown" ref={menuRef}>
                        <Stack gutter={0}>
                            <Body2
                                fontSize={30}
                                textAlign="center"
                                fontFamily="Poppins"
                                fontWeigth={600}
                                color={theme.colors.white}
                            >
                                {tCommon('common/getTheAppForFree')}
                            </Body2>
                            <Body2 fontFamily="Inter" textAlign="center" fontWeigth={400} color={theme.colors.white}>
                                {tCommon('common/accessStudyNotes')}
                            </Body2>
                        </Stack>
                        <div className="qr">
                            <NextImage
                                src={QRCodeFrame.src}
                                width={QRCodeFrame.width}
                                height={QRCodeFrame.height}
                                alt="qr code frame"
                                className="frame"
                            />
                            <DownloadQRCode
                                size={98}
                                referrerScreen="webapp-feature-not-available-qr-code"
                                codeColor={theme.colors.subtitleBlack}
                            />
                        </div>
                        <Body2 fontFamily="Inter" fontWeigth={400} color={theme.colors.white}>
                            {tCommon('common/openCameraToScanQRCode')}
                        </Body2>
                        <div className="separator">
                            <div />
                            <Body2 fontFamily="Inter" fontWeigth={400} color={theme.colors.white}>
                                {tCommon('common/orLabel')}
                            </Body2>
                            <div />
                        </div>
                        <div className="buttons">
                            <div onClick={() => onStoreButtonClick('ios')}>{returnDownloadButton(parsedLocale, 'ios')}</div>
                            <div onClick={() => onStoreButtonClick('android')}>
                                {returnDownloadButton(parsedLocale, 'android')}
                            </div>
                        </div>
                    </div>
                    <div className="background-shadow" />
                </>
            ) : null}
        </Container>
    )
}

export default HeaderDownloadDesktopButton
