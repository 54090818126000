import AppStoreFR from 'assets/know/app_store_fr.webp'
import GooglePlayFR from 'assets/know/google_play_fr.webp'
import AppStoreDE from 'assets/know/app_store_de.webp'
import GooglePlayDE from 'assets/know/google_play_de.webp'
import AppStoreEN from 'assets/know/app_store_en.webp'
import GooglePlayEN from 'assets/know/google_play_en.webp'
import AppStoreIT from 'assets/know/app_store_it.webp'
import GooglePlayIT from 'assets/know/google_play_it.webp'
import AppStorePL from 'assets/know/app_store_pl.webp'
import GooglePlayPL from 'assets/know/google_play_pl.webp'
import AppStoreTR from 'assets/know/app_store_tr.webp'
import GooglePlayTR from 'assets/know/google_play_tr.webp'
import AppStoreES from 'assets/know/app_store_es.webp'
import GooglePlayES from 'assets/know/google_play_es.webp'
import { ParsedLocale } from 'lib/constants/localization'
import { Language } from 'interfaces/Language'
import styled from 'styled-components'

const Image = styled.img`
    cursor: pointer;
`

export const returnDownloadButton = ({ contentLanguageCode }: ParsedLocale, type: 'android' | 'ios') => {
    const isAndroid = type === 'android'
    switch (contentLanguageCode) {
        case Language.German:
            return isAndroid ? (
                <Image src={GooglePlayDE.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreDE.src} height={46} alt="App Store download button" />
            )
        case Language.AmericanEnglish:
        case Language.BritishEnglish:
            return isAndroid ? (
                <Image src={GooglePlayEN.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreEN.src} height={46} alt="App Store download button" />
            )
        case Language.Spanish:
        case Language.ColombiaSpanish:
            return isAndroid ? (
                <Image src={GooglePlayES.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreES.src} height={46} alt="App Store download button" />
            )
        case Language.French:
            return isAndroid ? (
                <Image src={GooglePlayFR.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreFR.src} height={46} alt="App Store download button" />
            )
        case Language.Italian:
            return isAndroid ? (
                <Image src={GooglePlayIT.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreIT.src} height={46} alt="App Store download button" />
            )
        case Language.Polish:
            return isAndroid ? (
                <Image src={GooglePlayPL.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStorePL.src} height={46} alt="App Store download button" />
            )
        case Language.Turkish:
            return isAndroid ? (
                <Image src={GooglePlayTR.src} height={46} alt="Google Play download button" />
            ) : (
                <Image src={AppStoreTR.src} height={46} alt="App Store download button" />
            )
        default:
            throw new Error(`Unsupported combination: type=${type}, language=${contentLanguageCode}`)
    }
}
