import { ActiveExperiments, getActiveExperiments } from 'lib/features/activeExperiments'
import { useEffect, useState } from 'react'

const useExperiments = () => {
    const [activeExperiments, setActiveExperiments] = useState<ActiveExperiments | null>()

    useEffect(() => {
        setActiveExperiments(getActiveExperiments())
    }, [])

    return activeExperiments
}

export default useExperiments
