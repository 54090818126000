import { GetServerSidePropsContext } from 'next'
import { parseCookies, setCookie } from 'nookies'

const ActiveExperimentsKEY = 'ACTIVE_EXPERIMENTS'

export type ActiveExperiments = Record<ExperimentKey, ExperimentVariant>

export enum ExperimentKey {
    test = 'TEST',
    showDownloadAppDropdown = 'SHOW_DOWNLOAD_APP_DROPDOWN',
    showDarkThemeAiEnhancedPage = 'SHOW_DARK_THEME_AI_ENHANCED_PAGE',
    showDeclutteredHeaderSection = 'SHOW_DECLUTTERED_HEADER_SECTION',
    putMoreMobileThumbnailButtonEmphasis = 'PUT_MORE_MOBILE_THUMBNAIL_BUTTON_EMPHASIS',
    showSignUpBannerEmphasisDesign = 'SHOW_SIGNUP_BANNER_EMPHASIS_DESIGN',
}

export enum ExperimentVariant {
    testBaseline = 'TEST_0',
    testVariant = 'TEST_1',
    showDownloadAppDropdownVariant0 = 'SHOW_DOWNLOAD_APP_DROPDOWN_VARIANT_0',
    showDownloadAppDropdownVariant1 = 'SHOW_DOWNLOAD_APP_DROPDOWN_VARIANT_1', // show conversion dropdown on navbar click
    showDarkThemeAiEnhancedPageVariant0 = 'SHOW_DARK_THEME_AI_ENHANCED_PAGE_VARIANT_0',
    showDarkThemeAiEnhancedPageVariant1 = 'SHOW_DARK_THEME_AI_ENHANCED_PAGE_VARIANT_1', // Dark theme know page desktop + mobile
    showDeclutteredHeaderSectionVariant0 = 'SHOW_DECLUTTERED_HEADER_SECTION_VARIANT_0',
    showDeclutteredHeaderSectionVariant1 = 'SHOW_DECLUTTERED_HEADER_SECTION_VARIANT_1', // Hide know thumbnail for desktop
    putMoreMobileThumbnailButtonEmphasisVariant0 = 'PUT_MORE_MOBILE_THUMBNAIL_BUTTON_EMPHASIS_VARIANT_0',
    putMoreMobileThumbnailButtonEmphasisVariant1 = 'PUT_MORE_MOBILE_THUMBNAIL_BUTTON_EMPHASIS_VARIANT_1', // change thumbnail button background color to dark
    putMoreMobileThumbnailButtonEmphasisVariant2 = 'PUT_MORE_MOBILE_THUMBNAIL_BUTTON_EMPHASIS_VARIANT_2', // change thumbnail button background + make thumbnail bigger on mobile
    showSignUpBannerEmphasisDesignVariant0 = 'SHOW_SIGNUP_BANNER_EMPHASIS_DESIGN_VARIANT_0',
    showSignUpBannerEmphasisDesignVariant1 = 'SHOW_SIGNUP_BANNER_EMPHASIS_DESIGN_VARIANT_1', // show white signup banner design
    showSignUpBannerEmphasisDesignVariant2 = 'SHOW_SIGNUP_BANNER_EMPHASIS_DESIGN_VARIANT_2', // show white signup banner design + assets
}

function getRandomVariant(variants: ExperimentVariant[]): ExperimentVariant {
    return variants[Math.floor(Math.random() * variants.length)]
}

function getStoredVariant(storedVariants: string[], variants: ExperimentVariant[]): ExperimentVariant | null {
    for (const variant of variants) {
        if (storedVariants.includes(variant)) {
            return variant
        }
    }

    return null
}

function getExperimentVariant(possibleVariants: ExperimentVariant[], storedVariants: string[]): ExperimentVariant {
    const storedVariant = getStoredVariant(storedVariants, possibleVariants)
    if (storedVariant !== null) {
        return storedVariant
    } else {
        return getRandomVariant(possibleVariants)
    }
}

export function getActiveExperiments(ctx?: GetServerSidePropsContext): ActiveExperiments {
    const storedVariants = getActiveExperimentsStore(ctx)

    const activeExperiments: ActiveExperiments = {
        TEST: getExperimentVariant([ExperimentVariant.testBaseline, ExperimentVariant.testVariant], storedVariants),
        SHOW_DOWNLOAD_APP_DROPDOWN: getExperimentVariant(
            [ExperimentVariant.showDownloadAppDropdownVariant0, ExperimentVariant.showDownloadAppDropdownVariant1],
            storedVariants
        ),
        SHOW_DARK_THEME_AI_ENHANCED_PAGE: getExperimentVariant(
            [ExperimentVariant.showDarkThemeAiEnhancedPageVariant0, ExperimentVariant.showDarkThemeAiEnhancedPageVariant1],
            storedVariants
        ),
        SHOW_DECLUTTERED_HEADER_SECTION: getExperimentVariant(
            [ExperimentVariant.showDeclutteredHeaderSectionVariant0, ExperimentVariant.showDeclutteredHeaderSectionVariant1],
            storedVariants
        ),
        PUT_MORE_MOBILE_THUMBNAIL_BUTTON_EMPHASIS: getExperimentVariant(
            [
                ExperimentVariant.putMoreMobileThumbnailButtonEmphasisVariant0,
                ExperimentVariant.putMoreMobileThumbnailButtonEmphasisVariant1,
                ExperimentVariant.putMoreMobileThumbnailButtonEmphasisVariant2,
            ],
            storedVariants
        ),
        SHOW_SIGNUP_BANNER_EMPHASIS_DESIGN: getExperimentVariant(
            [
                ExperimentVariant.showSignUpBannerEmphasisDesignVariant0,
                ExperimentVariant.showSignUpBannerEmphasisDesignVariant1,
                ExperimentVariant.showSignUpBannerEmphasisDesignVariant2,
            ],
            storedVariants
        ),
    }

    storeActiveExperiments(Object.values(activeExperiments), ctx)

    return activeExperiments
}

export function getActiveExperimentsVariants() {
    const activeExperiments = getActiveExperiments()
    return Object.values(activeExperiments)
}

function isValidExperiment(experiment: ExperimentVariant, possibleVariants: ExperimentVariant[]): boolean {
    return possibleVariants.includes(experiment)
}

function cachedExperimentsAreValid(cachedExperiments: ActiveExperiments | null): boolean {
    if (!cachedExperiments) return false

    const allPossibleVariants = Object.values(ExperimentVariant)
    return Object.values(cachedExperiments).every((variant) => isValidExperiment(variant, allPossibleVariants))
}

// Active Experiments
export function getActiveExperimentsStore(ctx?: GetServerSidePropsContext): string[] {
    const cookies = parseCookies(ctx)
    const item = cookies[ActiveExperimentsKEY]
    if (!item) {
        return []
    }
    return item.split(',')
}

export const storeActiveExperiments = (experiments: string[], ctx?: GetServerSidePropsContext) => {
    const stringifiedValue = experiments.join(',')
    setCookie(ctx, ActiveExperimentsKEY, stringifiedValue, {
        maxAge: 30 * 24 * 60 * 60,
        path: '/',
    })
}
